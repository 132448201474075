<template>
  <DataView
    :alwaysShowPaginator="false"
    :loading="loading"
    :rows="pagination.per_page"
    :totalRecords="objectList?.count"
    :value="objectList?.results"
    class="border-none"
    dataKey="id"
    lazy
    paginator
    @page="(event) => { pagination = getPagination(event) }"
  >
    <template #list="{ items }">
      <div class="flex gap-2">
        <div v-for="tag in items" :key="tag.id">
          <Checkbox v-model="tagIds" :inputId="`tag_${tag.id}`" name="tags" :value="tag.id" />
          <label :for="`tag_${tag.id}`" class="ml-2">
            <Tag
              :style="`background-color: #${tag.color};`"
              :value="tag.name"
            />
          </label>
        </div>
      </div>
    </template>
  </DataView>
    <div class="flex justify-between pt-5">
      <Button
        :disabled="loading"
        :loading="loading"
        label="Tag"
        icon="pi pi-plus"
        link
        size="small"
        @click="onAddTag"
      />
      <Button
        :disabled="loading"
        :loading="loading"
        label="Save"
        @click="onSave"
      />
    </div>
</template>

<script lang="ts" setup>
import DialogTagAddChange from '~/components/dialogs/DialogTagAddChange.vue'
import type {DynamicDialogInstance} from 'primevue/dynamicdialogoptions'

const dialog = useDialog()
const dialogRef = inject<ComputedRef<DynamicDialogInstance>>('dialogRef')
const tagIds = ref<number[]>([])

const pagination = ref<PaginationInput>({
  page: 1,
  per_page: 10,
})

const { data: objectList, pending: loading, refresh } = useApi<Paginated<Tag>, ApiError>('/tags/', {
  query: pagination,
  watch: [pagination]
})

onBeforeMount(() => {
  if (dialogRef?.value?.data?.tagIds) {
    tagIds.value = dialogRef.value.data.tagIds
  }
})

const onAddTag = () => {
  dialog.open(DialogTagAddChange, {
    props: {
      breakpoints:{
        '960px': '75vw',
        '640px': '90vw'
      },
      header: 'Add tag',
      modal: true,
      style: {
        width: '30vw',
      }
    },
    onClose: () => refresh()
  })
}

const onSave = () => {
  dialogRef?.value.close({ tagIds: tagIds.value })
}
</script>
